var video_icon = '';
var text_icon = '';
var photo_icon = '';

jQuery(document).ready(function() {
  video_icon = {
    url: vars.theme_url + '/assets/images/map-pin-orange.png', // url
    scaledSize: new google.maps.Size(20, 32), // scaled size
    origin: new google.maps.Point(0, 0), // origin
    anchor: new google.maps.Point(10, 32), // anchor
    labelOrigin: new google.maps.Point(10, 12),
    label: {
      text: '',
      color: 'white'
    }
  };
  text_icon = {
    url: vars.theme_url + '/assets/images/map-pin-tan.png', // url
    scaledSize: new google.maps.Size(20, 32), // scaled size
    origin: new google.maps.Point(0, 0), // origin
    anchor: new google.maps.Point(10, 32), // anchor
    labelOrigin: new google.maps.Point(10, 12),
    label: {
      text: '',
      color: 'white'
    }
  };
  photo_icon = {
    url: vars.theme_url + '/assets/images/map-pin-green.png', // url
    scaledSize: new google.maps.Size(20, 32), // scaled size
    origin: new google.maps.Point(0, 0), // origin
    anchor: new google.maps.Point(10, 32), // anchor
    labelOrigin: new google.maps.Point(10, 12),
    label: {
      text: '',
      color: 'white'
    }
  };
});

/*
 *  add_marker
 *
 *  This function will add a marker to the selected Google Map
 *
 *  @type	function
 *  @date	8/11/2013
 *  @since	4.3.0
 *
 *  @param	$marker (jQuery element)
 *  @param	map (Google Map object)
 *  @return	n/a
 */
function add_marker($marker, map) {
  // var
  var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

  var icon = '';
  if ($marker.attr('data-icon') === 'video_icon') {
    icon = video_icon;
  } else if ($marker.attr('data-icon') === 'photo_icon') {
    icon = photo_icon;
  } else {
    icon = text_icon;
  }

  // create marker
  var marker = new google.maps.Marker({
    position: latlng,
    map: map,
    icon: icon
  });

  // add to array
  map.markers.push(marker);

  // show info window when marker is clicked
  google.maps.event.addListener(marker, 'click', function() {

    // Show the modal and the overlay
    jQuery('#modal-' + $marker.attr('data-id')).addClass('show');
    jQuery('body').addClass('noscroll');
    jQuery(".wrapper").toggleClass("menu-open");

    // Slide the menu up
    jQuery('body').addClass('hidemenu');

    // Transition stuff
    setTimeout(function() {
      jQuery(".page-overlay").toggleClass("with-transitions");
      jQuery('#modal-' + $marker.attr('data-id')).addClass('with-transitions');
    }, 20);

  });

}

/*
 *  center_map
 *
 *  This function will center the map, showing all markers attached to this map
 *
 *  @type	function
 *  @date	8/11/2013
 *  @since	4.3.0
 *
 *  @param	map (Google Map object)
 *  @return	n/a
 */

function center_map(map) {

  // vars
  var bounds = new google.maps.LatLngBounds();

  // loop through all markers and create bounds
  jQuery.each(map.markers, function(i, marker) {

    var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());

    bounds.extend(latlng);

  });

  map.setCenter(new google.maps.LatLng(43.0506829, -76.151023));
  map.setZoom(10);

  // only 1 marker?
  /*
  if (map.markers.length === 1) {
    // set center of map
    map.setCenter(bounds.getCenter());
    map.setZoom(16);
  } else {
    // fit to bounds
    map.fitBounds(bounds);
  }
  */


}


/*
 *  new_map
 *
 *  This function will render a Google Map onto the selected jQuery element
 *
 *  @type	function
 *  @date	8/11/2013
 *  @since	4.3.0
 *
 *  @param	$el (jQuery element)
 *  @return	n/a
 */

function new_map($el) {

  // var
  var $markers = $el.find('.marker');

  // vars
  var args = {
    zoom: 16,
    center: new google.maps.LatLng(43.047932, -76.161265),
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    scrollwheel: false,
    zoomControl: true
  };


  // create map
  var map = new google.maps.Map($el[0], args);


  // add a markers reference
  map.markers = [];


  // add markers
  $markers.each(function() {

    add_marker(jQuery(this), map);

  });

  // center map
  center_map(map);

  // return
  return map;
}






function set_map_styles(map) {

  var styles = new google.maps.StyledMapType([{
      "featureType": "administrative",
      "elementType": "all",
      "stylers": [{
        "visibility": "simplified"
      }]
    },
    {
      "featureType": "administrative",
      "elementType": "labels.text.fill",
      "stylers": [{
        "color": "#444444"
      }]
    },
    {
      "featureType": "landscape",
      "elementType": "all",
      "stylers": [{
          "color": "#c5a069"
        },
        {
          "lightness": "14"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "all",
      "stylers": [{
        "visibility": "off"
      }]
    },
    {
      "featureType": "road",
      "elementType": "all",
      "stylers": [{
          "saturation": "-100"
        },
        {
          "lightness": 45
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "all",
      "stylers": [{
          "color": "#ba8e58"
        },
        {
          "visibility": "simplified"
        },
        {
          "lightness": "0"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels",
      "stylers": [{
        "visibility": "off"
      }]
    },
    {
      "featureType": "road.arterial",
      "elementType": "all",
      "stylers": [{
          "visibility": "simplified"
        },
        {
          "color": "#ba8e58"
        },
        {
          "lightness": "9"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels",
      "stylers": [{
          "visibility": "simplified"
        },
        {
          "color": "#a19d8c"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.icon",
      "stylers": [{
        "visibility": "off"
      }]
    },
    {
      "featureType": "road.local",
      "elementType": "all",
      "stylers": [{
          "visibility": "off"
        },
        {
          "saturation": "-100"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "all",
      "stylers": [{
        "visibility": "off"
      }]
    },
    {
      "featureType": "water",
      "elementType": "all",
      "stylers": [{
          "color": "#8aaebe"
        },
        {
          "visibility": "on"
        }
      ]
    }
  ], {
    name: 'Styled Map'
  });

  map.mapTypes.set('styled_map', styles);
  map.setMapTypeId('styled_map');
}

var gf_map;
var gf_marker;

jQuery(document).ready(function() {
  var canal_layer;

  //
  // Load the reflections map
  //
  if (jQuery('.ref-map').length > 0) {
    // create map
    map = new_map(jQuery('.ref-map'));

    set_map_styles(map);

    canal_layer = new google.maps.KmlLayer({
      url: 'https://www.tothelab.co/erie-path7.kml',
      map: map
    });

    barge_layer = new google.maps.KmlLayer({
      url: 'https://www.tothelab.co/barge_path.kml',
      map: map
    });


  }



  //
  // Load the gravity forms map
  //
  if (jQuery('.gf-map').length > 0) {
    // create map
    gf_map = new_map(jQuery('.gf-map'));

    set_map_styles(gf_map);

    gf_marker = new google.maps.Marker({
      map: gf_map,
      anchorPoint: new google.maps.Point(0, -29)
    });


    canal_layer = new google.maps.KmlLayer({
      url: 'https://www.tothelab.co/erie-path7.kml',
      map: gf_map
    });

    barge_layer = new google.maps.KmlLayer({
      url: 'https://www.tothelab.co/barge_path.kml',
      map: gf_map
    });
  }



  // Close all modal windows
  jQuery('.close-modal').on('click', function(event) {

    // Close the modal and overlay
    jQuery('body').removeClass('noscroll');
    jQuery(".page-overlay").removeClass("with-transitions");
    jQuery('.lab-modal').removeClass('with-transitions');

    // Slide the menu down
    jQuery('body').removeClass('hidemenu');

    // Transition stuff
    setTimeout(function() {
      jQuery('.lab-modal').removeClass('show');
      jQuery(".wrapper").removeClass("menu-open");
    }, 270);

    return false;
  });
});







/*
 * Init the start and ending point autocomplete functionality via Google Maps API
 *
 * @return n/a
 */
function initAutocomplete() {

  // Create the autocomplete object, restricting the search to geographical
  // location types.
  if (jQuery('#input_1_6').length > 0) {
    autocomplete = new google.maps.places.Autocomplete(
      /** @type {!HTMLInputElement} */
      (document.getElementById('input_1_6')), {
        types: ['geocode']
      });

    autocomplete.addListener('place_changed', function() {
      gf_marker.setVisible(false);
      var place = autocomplete.getPlace();
      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        window.alert("No details available for input: '" + place.name + "'");
        return;
      }

      // If the place has a geometry, then present it on a map.
      if (place.geometry.viewport) {
        gf_map.fitBounds(place.geometry.viewport);
      } else {
        gf_map.setCenter(place.geometry.location);
      }
      gf_map.setZoom(12);
      gf_marker.setPosition(place.geometry.location);
      gf_marker.setVisible(true);

      var lat = gf_marker.getPosition().lat();
      var lng = gf_marker.getPosition().lng();

      // Update hidden location value.
      jQuery('#input_1_8').val(lat + ',' + lng);
    });
  }
}








jQuery(document).ready(function() {

  // Turn browser autocomplete off for this field
  jQuery('#input_1_6').attr('autocomplete', 'off');

  jQuery('.slide-nav-trigger').on('click', function() {

    if (jQuery('header').hasClass('open')) {
      jQuery('header').removeClass('open');
      jQuery('.slide-nav-trigger').html('Show Menu');

      jQuery(".page-overlay").removeClass("with-transitions");
      setTimeout(function() {
        jQuery(".wrapper").removeClass("menu-open");
      }, 220);
    } else {
      jQuery('header').addClass('open');
      jQuery('.slide-nav-trigger').html('Hide Menu');

      jQuery(".wrapper").addClass("menu-open");
      setTimeout(function() {
        jQuery(".page-overlay").addClass("with-transitions");
      }, 20);
    }
  });


});